import { H1, H2, P } from '@expo/html-elements';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { lgBreakpoint, smBreakpoint } from '../constants';
import { useDimensions } from '../context/DimensionsContext';
import { useThemeState } from '../context/ThemeContext';
import {
  GTAmerica,
  GTAmericaCompressed,
  mdbreakpoint,
  PanamaBold,
} from '../libs/constants';

interface Props { }

export const LargeCanvasScreen: React.FC<Props> = () => {
  document.title = 'Large Canvas Painting | Happy Medium';
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 16 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.07,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
    header: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: 'uppercase',
      alignSelf: 'center',
      fontSize: width < smBreakpoint ? 34 : width < mdbreakpoint ? 42 : 48,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 38 : width < mdbreakpoint ? 48 : 54,
      textAlign: 'center',
    },
    h2: {
      fontSize: width < smBreakpoint ? 28 : width < mdbreakpoint ? 34 : 40,
    },
    h3: {
      fontSize: width < smBreakpoint ? 22 : width < mdbreakpoint ? 24 : 28,
    },
    pushButton: {
      borderWidth: 1,
      borderColor: theme.foregroundColor,
      borderRadius: 5,
      // padding: 5,
      justifyContent: 'center',
      height: 100,
      // width: 222,
      marginHorizontal: width < mdbreakpoint ? 0 : 5,
      marginVertical: 5,
    },
    timeSlot: { width: width < lgBreakpoint ? 140 : 222 },
    item: { width: 222 },
  });

  type Size = 'Large' | 'Larger' | 'Largest';
  type Date = '8/13' | '8/14' | '8/15' | '8/20' | '8/21' | '8/22' | '9/17' | '9/18' | '9/19';
  type Frame = 'clear pine' | 'mahogany';
  type Location = 'Manhattan' | 'Brooklyn'
  const [selectedSize, setSelectedSize] = useState<Size>('Large');
  const [selectedDate, setSelectedDate] = useState<Date>('9/17');
  const [selectedFrame, setSelectedFrame] = useState<Frame>('clear pine');
  const [selectedLocation, setSelectedLocation] = useState<Location>('Manhattan');
  type PaymentLink = {
    size: Size;
    paymentLink: string;
    price: string;
    isAvailable?: boolean;
    date: Date
    frame: Frame
    location: Location
  };
  const paymentLinks: PaymentLink[] = [

    {
      size: 'Large',
      date: '8/13',
      paymentLink: 'https://buy.stripe.com/14kdUw5CQ8iien659h',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Large',
      date: '8/13',
      paymentLink: 'https://buy.stripe.com/5kA3fSd5ibuu92M59i',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Larger',
      date: '8/13',
      paymentLink: 'https://buy.stripe.com/bIY5o0aXa7ee5QAgS1',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Larger',
      date: '8/13',
      paymentLink: 'https://buy.stripe.com/bIYcQs1mAfKKen6atE',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Largest',
      date: '8/13',
      paymentLink: 'https://buy.stripe.com/5kA8Ac9T6eGGceY9pB',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Largest',
      date: '8/13',
      paymentLink: 'https://buy.stripe.com/cN2aIk2qEaqqgve8ly',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Large',
      date: '8/14',
      paymentLink: 'https://buy.stripe.com/cN2cQs0iw6aa1Ak6dr',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Large',
      date: '8/14',
      paymentLink: 'https://buy.stripe.com/6oE9Eg4yM7eedj245k',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Larger',
      date: '8/14',
      paymentLink: 'https://buy.stripe.com/eVa7w8d5i422a6Q59p',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Larger',
      date: '8/14',
      paymentLink: 'https://buy.stripe.com/eVacQs6GUgOO3Is59q',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Largest',
      date: '8/14',
      paymentLink: 'https://buy.stripe.com/cN217K5CQ7ee5QAatL',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Largest',
      date: '8/14',
      paymentLink: 'https://buy.stripe.com/fZe6s49T6eGGbaU31k',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Large',
      date: '8/15',
      paymentLink: 'https://buy.stripe.com/5kA3fS2qE7eebaU8lF',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Large',
      date: '8/15',
      paymentLink: 'https://buy.stripe.com/dR6eYAc1e8ii5QAcBW',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Larger',
      date: '8/15',
      paymentLink: 'https://buy.stripe.com/dR69Eg2qEbuu7YIeK5',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Larger',
      date: '8/15',
      paymentLink: 'https://buy.stripe.com/8wMeYA0iw1TU92M59w',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Largest',
      date: '8/15',
      paymentLink: 'https://buy.stripe.com/dR6bMo7KYdCC92M59x',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Brooklyn'
    },
    {
      size: 'Largest',
      date: '8/15',
      paymentLink: 'https://buy.stripe.com/dR6bMo4yM1TUbaU8lK',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Brooklyn'
    },
    {
      size: 'Large',
      date: '8/20',
      paymentLink: 'https://buy.stripe.com/cN203Gc1e2XY2EofOd',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '8/20',
      paymentLink: 'https://buy.stripe.com/aEU5o02qEcyygvecC2',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '8/20',
      paymentLink: 'https://buy.stripe.com/8wM5o0d5ibuuen659B',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '8/20',
      paymentLink: 'https://buy.stripe.com/8wM3fSaXagOO7YIatW',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '8/20',
      paymentLink: 'https://buy.stripe.com/eVag2E4yMeGG1Ak9pT',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '8/20',
      paymentLink: 'https://buy.stripe.com/28o8Acc1ecyy0wgfOi',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '8/21',
      paymentLink: 'https://buy.stripe.com/7sIeYAfdq2XYdj29pV',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '8/21',
      paymentLink: 'https://buy.stripe.com/6oEaIk0iw4226UEby4',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '8/21',
      paymentLink: 'https://buy.stripe.com/fZe9Eg9T62XY1AkcC9',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '8/21',
      paymentLink: 'https://buy.stripe.com/5kAcQs2qEfKK2Eo9pY',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '8/21',
      paymentLink: 'https://buy.stripe.com/bIYeYAfdqbuubaU31B',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '8/21',
      paymentLink: 'https://buy.stripe.com/6oEeYA0iw6aa0wg45G',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '8/22',
      paymentLink: 'https://buy.stripe.com/cN23fSc1e6aa92MdGh',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '8/22',
      paymentLink: 'https://buy.stripe.com/fZe17K3uI1TUceY0Tw',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '8/22',
      paymentLink: 'https://buy.stripe.com/eVabMo8P22XY2Eo0Tx',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '8/22',
      paymentLink: 'https://buy.stripe.com/4gw17K1mA1TUgve31G',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '8/22',
      paymentLink: 'https://buy.stripe.com/bIY03G0iw566ceY59P',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '8/22',
      paymentLink: 'https://buy.stripe.com/14k8Acc1eeGG2Eo7hY',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },

    {
      size: 'Large',
      date: '9/17',
      paymentLink: 'https://buy.stripe.com/fZeaIk6GUcyyceYayj',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '9/17',
      paymentLink: 'https://buy.stripe.com/7sIaIkd5i4222Eo35S',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '9/17',
      paymentLink: 'https://buy.stripe.com/28o2bO5CQ2XYbaU7m9',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '9/17',
      paymentLink: 'https://buy.stripe.com/4gwaIk7KYeGGceY49Y',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '9/17',
      paymentLink: 'https://buy.stripe.com/9AQcQs7KYgOObaUgWL',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '9/17',
      paymentLink: 'https://buy.stripe.com/28oeYA0iw5664MwgWM',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '9/18',
      paymentLink: 'https://buy.stripe.com/9AQ3fSaXaeGG6UE0XP',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '9/18',
      paymentLink: 'https://buy.stripe.com/6oEg2E0iw0PQ3Is7me',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '9/18',
      paymentLink: 'https://buy.stripe.com/4gwdUw8P27ee3Isayr',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '9/18',
      paymentLink: 'https://buy.stripe.com/28o17K6GU7ee1Ak6ic',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '9/18',
      paymentLink: 'https://buy.stripe.com/4gw9Egfdq9mmen64a5',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '9/18',
      paymentLink: 'https://buy.stripe.com/14k5o0fdqdCC92MbCy',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '9/19',
      paymentLink: 'https://buy.stripe.com/eVa03G3uI9mmceY5eb',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '9/19',
      paymentLink: 'https://buy.stripe.com/cN26s4c1e2XY4Mw5ec',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '9/19',
      paymentLink: 'https://buy.stripe.com/6oE03G3uIcyy5QA0XX',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '9/19',
      paymentLink: 'https://buy.stripe.com/4gw9Eg1mA4220wgfSS',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '9/19',
      paymentLink: 'https://buy.stripe.com/dR6cQsghueGGbaUfST',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '9/19',
      paymentLink: 'https://buy.stripe.com/3cs6s4e9m6aaa6Q9uw',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },

  ]

  return (
    <>
      <ScrollView style={{ backgroundColor: theme.backgroundColor }}>
        <CuratorHeader />
        <View style={{ height: width < mdbreakpoint ? 20 : 40 }} />
        <View
          style={{
            justifyContent: 'center',
            width: 700,
            maxWidth: '90vw',
            alignSelf: 'center',
          }}
        >
          <H1 style={[styles.header, { marginBottom: 0 }]}>
            Large Canvas Painting
          </H1>
          <P
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                marginTop: 0,
                letterSpacing: 1.2,
                marginBottom: 0,
                width: 280,
                textAlign: 'center',
              },
            ]}
          >
            August 13th, 14th, 15th, 20th, 21st, and 22nd
            September 17th, 18th, and 19th
          </P>
          <View style={{ height: 10 }} />
          <Text
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: 0,
                letterSpacing: 1,
                fontSize: 10,
              },
            ]}
          >
            Manhattan & Brooklyn
          </Text>

          <P style={[styles.text, styles.body, { fontWeight: '700' }]}>
            Go big and go home.
          </P>
          <P style={[styles.text, styles.body]}>
            We all have that one spot in our home that needs a large piece of art - you know the one above the couch, bed, or entryway? Now's your chance to fill it with your own creativity.
          </P>
          <P style={[styles.text, styles.body]}>
            Join us from 6-9pm for an evening of large canvas painting at Happy Medium and leave with your new favorite piece.
          </P>
          <P style={[styles.text, styles.body]}>
            We will provide the paints, canvases, floating frames, inspiration and even sketch it for you if you want! You bring a buddy (or come solo) and get to work.
          </P>
          <P style={[styles.text, styles.body]}>
            Only at Happy Medium.
          </P>
          <P style={[styles.text, styles.body, { fontStyle: 'italic', fontSize: 14 }]}>
            **Please note, our tickets are non-refundable. You must notify us at reservations@happy-medium.co at least 72 hours before your time slot in order to be eligible to reschedule.** Local delivery available to the five boroughs only. Crate shipping available worldwide.
          </P>

          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Select your date</H2>


          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'space-around',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('8/13')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '8/13'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '8/13'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  August 13th (Brooklyn) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('8/14')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '8/14'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '8/14'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  August 14th (Brooklyn) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('8/15')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '8/15'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedDate === '8/15'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  August 15th (Brooklyn) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>

          </View>
          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'space-around',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('8/20')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '8/20'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '8/20'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  August 20th (Manhattan) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('8/21')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '8/21'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '8/21'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  August 21st (Manhattan) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('8/22')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '8/22'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedDate === '8/22'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  August 22nd (Manhattan) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>

          </View>

          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'space-around',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity disabled={false} style={{ alignSelf: 'center', opacity: 1 }} onPress={() => setSelectedDate('9/17')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '9/17'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '9/17'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  September 17th (Manhattan)
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={false} style={{ alignSelf: 'center', opacity: 1 }} onPress={() => setSelectedDate('9/18')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '9/18'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '9/18'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  September 18th (Manhattan)
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={false} style={{ alignSelf: 'center', opacity: 1 }} onPress={() => setSelectedDate('9/19')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '9/19'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedDate === '9/19'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  September 19th (Manhattan)
                </P>
              </View>
            </TouchableOpacity>

          </View>

          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Select your size</H2>

          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'space-around',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedSize('Large')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedSize === 'Large'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedSize === 'Large'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Large{'\n'}(36"x36")
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedSize('Larger')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedSize === 'Larger'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedSize === 'Larger'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Larger{'\n'}(40"x58")
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedSize('Largest')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedSize === 'Largest'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedSize === 'Largest'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  Largest{'\n'}(48"x78")
                </P>
              </View>
            </TouchableOpacity>

          </View>

          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Select your frame</H2>

          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'center',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedFrame('clear pine')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedFrame === 'clear pine'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedFrame === 'clear pine'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  clear pine{'\n'}(light)
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedFrame('mahogany')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedFrame === 'mahogany'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedFrame === 'mahogany'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Mahogany{'\n'}(dark)
                </P>
              </View>
            </TouchableOpacity>

          </View>
        </View>
        <View style={{ height: 80 }} />
      </ScrollView>
      <TouchableOpacity disabled={false} style={{ opacity: 1 }} onPress={() => window.open(paymentLinks.find(l => l.size === selectedSize && l.frame === selectedFrame && l.date === selectedDate)?.paymentLink, '__blank')}>
        <View style={{ position: 'absolute', bottom: 0, right: width < mdbreakpoint ? undefined : 0, backgroundColor: theme.foregroundColor, borderRadius: 50, flexDirection: 'row', justifyContent: 'space-between', padding: 15, paddingHorizontal: 60, margin: 20, width: 320, alignSelf: 'center', borderWidth: 1, borderColor: theme.backgroundColor }}>
          {/* <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Sold Out</Text> */}

          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Checkout</Text>
          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>{paymentLinks.find(l => l.size === selectedSize && l.frame === selectedFrame)?.price}</Text>
        </View>
      </TouchableOpacity>
    </>
  );
};
